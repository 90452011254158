export type SettingsKey = 'DeviceId' | 'enableFlip'

export function saveSettings (key: SettingsKey, value: string | number | null | boolean) {
  window.localStorage.setItem(key, JSON.stringify(value))
}

export function loadSettings (key: SettingsKey) {
  const value = window.localStorage.getItem(key)
  if (!value) {
    return value
  }
  try {
    return JSON.parse(value)
  } catch (e) {
    return value
  }
}
