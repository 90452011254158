import React, { Component, ChangeEvent, MouseEvent } from 'react'
import jsQR from 'jsqr'
import './App.scss'
import { loadSettings, saveSettings } from '../lib/settings'
import Twitter from 'twitter-text'

interface State {
  errors: string[],
  videoDevices: MediaDeviceInfo[],
  result: string[],
  deviceId: string | undefined,
  showLicenses: boolean,
  showPrivacyPolicy: boolean
}

type CanvasPosition = {x: number, y: number}
function drawLine(canvas: CanvasRenderingContext2D, begin: CanvasPosition, end: CanvasPosition, color: string) {
  canvas.beginPath()
  canvas.moveTo(begin.x, begin.y)
  canvas.lineTo(end.x, end.y)
  canvas.lineWidth = 4
  canvas.strokeStyle = color
  canvas.stroke()
}

export class App extends Component <unknown, State> {
  videoElement: HTMLVideoElement | null = null
  canvasElement: HTMLCanvasElement | null = null
  canvas: CanvasRenderingContext2D | null = null
  enableFlip = false
  state: State = {
    errors: [],
    videoDevices: [],
    result: [],
    deviceId: undefined,
    showLicenses: false,
    showPrivacyPolicy: false
  }
  isChangingVideoDevice = false

  componentDidMount() {
    this.enableFlip = loadSettings('enableFlip') || false
    setTimeout(this.setup, 1000)
  }

  setup = async () => {
    try {
      const deviceId = loadSettings('DeviceId')
      const constraints: MediaStreamConstraints = deviceId ? { audio: false, video: { deviceId } } : { video: { facingMode: 'environment' } }
      const stream = await navigator.mediaDevices.getUserMedia(constraints)
      this.setupWithStream(stream)
      this.setState({ deviceId })
    } catch (e) {
      window.alert('本サービスの利用にはWebカメラの利用を許可してください。' + e.name)
      return
    }

    // デバイスリストのセットアップ
    const devices = await navigator.mediaDevices.enumerateDevices()
    const videoDevices = devices.filter((device) => device.kind === 'videoinput')
    this.setState({ videoDevices })

    // TODO:
    //  navigator.mediaDevices.addEventListener('devicechange', () => {})

    // キャンバス要素を取得
    const canvas = this.canvasElement && this.canvasElement.getContext('2d')
    this.canvas = canvas
  }

  async setupWithStream (stream: MediaStream) {
    const { videoElement } = this
    if (!videoElement) return

    // stream.getTracks().forEach((track) => {
    //   console.log(track.id, track.label, track.getConstraints(), track.getCapabilities(), track.getSettings())
    // })

    videoElement.srcObject = stream
    await videoElement.play()
    requestAnimationFrame(this.tick)
  }

  onChangeVideoDevice = async (e: ChangeEvent<HTMLSelectElement>) => {
    this.isChangingVideoDevice = true
    const deviceId = e.currentTarget.value

    saveSettings('DeviceId', deviceId)

    const stream = await navigator.mediaDevices.getUserMedia({ audio: false, video: { deviceId } })
    this.setupWithStream(stream)
    this.isChangingVideoDevice = false

    this.setState({ deviceId })
  }

  tick = () => {
    const { isChangingVideoDevice, videoElement, canvas, canvasElement, enableFlip } = this

    // デバイス変更中はさっさと終わる
    if (isChangingVideoDevice)
      return

    // HTML要素がまだ生成前なら何もせず終わる
    if (!(videoElement && canvas && canvasElement))
      return

    // loadingMessage.innerText = "⌛ Loading video..."
    if (videoElement.readyState === videoElement.HAVE_ENOUGH_DATA) {
      const { videoWidth, videoHeight } = videoElement
      canvasElement.height = videoHeight
      canvasElement.width = videoWidth

      if (enableFlip) {
        canvas.scale(-1,1)
        canvas.translate(-videoWidth, 0)
        canvas.drawImage(videoElement, 0, 0, videoWidth, videoHeight)
        canvas.resetTransform()
      } else {
        canvas.drawImage(videoElement, 0, 0, videoWidth, videoHeight)
      }

      const imageData = canvas.getImageData(0, 0, videoWidth, videoHeight)
      const code = jsQR(imageData.data, imageData.width, imageData.height, {
        inversionAttempts: 'dontInvert',
      })
      if (code) {
        const { location, data } = code
        const { topLeftCorner, topRightCorner, bottomRightCorner, bottomLeftCorner } = location
        const color = '#FF3B58'
        drawLine(canvas, topLeftCorner, topRightCorner, color)
        drawLine(canvas, topRightCorner, bottomRightCorner, color)
        drawLine(canvas, bottomRightCorner, bottomLeftCorner, color)
        drawLine(canvas, bottomLeftCorner, topLeftCorner, color)

        if (!data)
          return

        const { result: prevResult } = this.state
        const prevResultWithoutDuplicated = prevResult.filter((prevRes) => prevRes !== data)

        this.setState({ result: [data].concat(prevResultWithoutDuplicated) })
      } else {
        // QRコード未検出時の処理
      }
    }

    requestAnimationFrame(this.tick)
  }

  onToggleFlip = () => {
    this.enableFlip = !this.enableFlip
    saveSettings('enableFlip', this.enableFlip)
  }

  onToggleLicenses = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    this.setState({ showLicenses: !this.state.showLicenses })
  }

  onTogglePrivacyPolicy = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    this.setState({ showPrivacyPolicy: !this.state.showPrivacyPolicy })
  }

  render () {
    const { videoDevices, result, deviceId, showLicenses, showPrivacyPolicy } = this.state
    return (
      <div className="app">
        <div className='card'>
          <header>
            Online QR Code reader
          </header>
          <section>
            <div className='camera-container'>
              <video ref={(el) => this.videoElement = el} playsInline />
              <canvas ref={(el) => this.canvasElement = el} />
              <button className='flip-button' onClick={this.onToggleFlip}>
                <i className='fa fa-arrows-h' />
              </button>
            </div>
          </section>
          <section>
            <div>
              <select className='video-devices-select' onChange={this.onChangeVideoDevice} value={deviceId}>
                {
                  videoDevices.map((videoDevice) => (
                    <option key={videoDevice.deviceId} value={videoDevice.deviceId}>
                      {videoDevice.label}
                    </option>
                  ))
                }
              </select>
            </div>
          </section>
          <section className='result-container'>
            {
              result.map((res, index) => (
                <div key={`result-${index}`} dangerouslySetInnerHTML={{ __html: Twitter.autoLink(Twitter.htmlEscape(res), { targetBlank: true }) }} />
              ))
            }
          </section>
          <footer>
            <div className='licenses'>
              <a className='show-licenses' onClick={this.onToggleLicenses} href='/licenses.html' target='_blank' rel="noopener noreferrer">
                Licenses
                { ' ' + (showLicenses ? '△' : '▽') }
              </a>
              &nbsp;|&nbsp;
              <a className='show-licenses' onClick={this.onTogglePrivacyPolicy} href='/privacy.html' target='_blank' rel="noopener noreferrer">
                Privacy Policy
                { ' ' + (showPrivacyPolicy ? '△' : '▽') }
              </a>
            </div>
            <div className='copyright' ><a href='https://twitter.com/akiroom' target='_blank' rel="noopener noreferrer">@akiroom</a></div>
          </footer>
          {
            showLicenses && (
              <section>
                <iframe title='license information' className='detail-information' src='/licenses.html' style={{ display: showLicenses ? 'block' : 'none' }} />
              </section>
            )
          }
          {
            showPrivacyPolicy && (
              <section>
                <iframe title='license information' className='detail-information' src='/privacy.html' style={{ display: showPrivacyPolicy ? 'block' : 'none' }} />
              </section>
            )
          }
        </div>
      </div>
    )
  }
}
